import DialogBox from '@/component/dialog'
import refund_hange_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get'
import refund_hange_policy_save from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_save'

export default {
  name: "ticketSet",
  data() {
    return {
      dialogShow: false,
      dialogType: '国内机票',
      formData: {
        refund: 0,
        ticket: 0
      },
      detail: {}
    };
  },
  components: { DialogBox },
  created() {
    this._refund_hange_policy_get()
  },

  methods: {
    getPolicyText(type = Number) {
      let Text = ''
      switch (type) {
        case 1:
          Text = '选择原因'
          break;
        default:
          Text = '不限'
          break;
      }
      return Text
    },
    cancel() {
      this.dialogShow = false
    },
    save() {
      let type = this.dialogType
      let data = {}
      let _get = (name) => {
        let _data = this.formData
        return _data[name]
      }
      if (type === 1) {
        data.airChange = _get('ticket')
        data.airRefund = _get('refund')
      } else {
        data.carChange = _get('ticket')
        data.carRefund = _get('refund')
      }
      data = Object.assign(this.detail, data)
      refund_hange_policy_save(data).then(res => {
        console.log(res)
        this.cancel()
        this.$message({ type: "success", message: "设置成功" })
        this._refund_hange_policy_get()
      })
    },
    /**
     * 
     * @param {*} type 1:机票 2:火车票
     */
    _getDialogShow(type) {
      this.dialogShow = true
      this.dialogType = type
      let _data = this.formData
      if (type === 1) {
        _data.refund = this.detail.airRefund
        _data.ticket = this.detail.airChange
      } else {
        _data.refund = this.detail.carRefund
        _data.ticket = this.detail.carChange
      }
    },
    /**
     *  datas: 0:不限, 10:出差行程变化, 20:天气因素, 30:定错机票, 40:定错车次
     */
    _refund_hange_policy_get() {
      refund_hange_policy_get().then(res => {
        console.log(res)
        this.detail = res.datas
      })
    }
  }
}